@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #025464;
  color: #EFDEC6;
  margin: 0 auto;
}

.header-section {
  text-align: center;
  padding: 50px;
}

.header-section h1 {
  font-size: 2.7rem;
  font-weight: 700;
}

.header-section p {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 10px;
}

.form-section {
  padding: 100px;
  background-color: #05A895;
}

textarea,
button {
  width: 100%;
  border-radius: 5px;
  border: none;
}
button:hover{
  background-color: #FB5A48 !important;
  border: none;
  border-radius: 20px;
}

.form-control {
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  outline: none;
  background-color:#EFDEC6;;
  color: #DADADB;
  transition: all 0.5s ease-in-out;
}

/* .form-control:focus {
  border-left: 5px solid #8E443D;
  border-top: 5px solid #8E443D;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
} */

.btn {
  background: #000000;
  color: #DADADB;
  padding: 20px 0;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin:5px;
}

ul{
  padding-left: 0px;
}

.homeList{
  padding: 50px;
  text-align: center;
  list-style: none;
  font-size: 1.2rem;
  font-weight: 500;
  margin:5px;
}

.homeList h2{
  padding-top:20px;
  text-align: left;
}

/* .btn:hover {
  border-left: 5px solid #8E443D;
  border-top: 5px solid #8E443D;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
} */

.hr-line {
  margin: 70px 0 20px;
  border: 1px solid #394032;
}

.answer-section {
  margin: 30px 0;
  position: relative;
  border-radius: 20px;
  border: 3px solid #8E443D;
}

.answer-section .question {
  background-color: #8E443D;
  padding: 20px;
  border-radius: 20px;
}

.privacy{
  padding: 50px;
  background-color: #05A895;
  margin: 20px 0px;
  >p{
    padding-top: 100px;
  }
}

.answer-section .answer {
  padding: 20px;
}

.answer-section .answer::before {
  content: "";
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
}

.copy-icon {
  position: absolute;
  background-color: #394032;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -10px;
  right: -10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.title{
  padding-top: 50px;
}

.openingP{
  font-size: 1.3rem !important;
  padding: 80px 0px;
}
.homeList{
    margin: 0px !important;
    background-color: #640C3F;
}


.homeList > h2{
  padding-bottom: 20px;
}
.homeList > li {
  padding: 30px 0px;
  text-align: left;
  text-decoration: none;
  display: block;
  >h3{
    padding: 10px 0px;
  }
}

.home-page{
  padding: 50px 50px 100px;
}

.buttonsFlex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:40px 0px;
  .btn{
    width: 45%;
    margin: 10px;
    padding: 15px;
  }
}


.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  animation: rotation 2s ease-in-out infinite;
  transform: translate(-50%, -50%);
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-modal{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:#025464;
}
p{
  margin-bottom: 1.5rem;
}

.card{
  border:none;
  background: none;
}
.card-body >p {
  font-size: 30px;
}

p.answer{
  white-space: pre-line;
  font-size: 24px;
}

.App-logo{
  max-width: 150px;
  height:auto;
  margin: 0 auto;
  display: block;
}

.character-section{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 100px 50px;
  > h2{
    padding-bottom: 50px;
  }
}
.campaign-section{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 100px 50px;
  >h2{
      padding-bottom: 50px;
    }
}

.hr-line{
  margin: 0px !important
}
.header{
  min-height: 100px;
}
.loading-text{
  min-height: 200px;
  font-size: 1.3rem !important;
  text-align: center !important;
}

.loading-text-long{
  font-size: .8rem !important;
  text-align: center !important;
}

footer{
  display: flex;
  justify-content: center;
  >p {
  padding: 20px;
  margin: 0px;
  }
  >a{
    padding: 20px;
    color:#EFDEC6
  }
}